import Vue from 'vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import store from '@/store'

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	// baseURL: 'https://some-domain.com/api/',
	// timeout: 1000,
	// headers: {'X-Custom-Header': 'foobar'}
	//baseURL : "https://lrcdigital.com/nomina/"
	baseURL : "https://api.sushijimmysan.com/"
})

//Add request interceptor
/*axiosIns.interceptors.request.use(
	(config) => {
		store.commit('app/setLoading', true);
		return config;
	},
	(error) => {
		store.commit('app/setLoading', false)
		return Promise.reject(error);
	}
);*/

//Add response interceptor
axiosIns.interceptors.response.use(
	(response) => {
		store.commit('app/setLoading', false)
		return response;
	},
	(error) => {
		store.commit('app/setLoading', false)
		switch (error.response.status) {
			//401 unauthorized
			case 401:
				Swal.fire({
					title: 'Sesión terminada',
					html: "Su sesión ha finalizado por inactividad.<br>Por favor inicie sesión nuevamente.",
					//iconHtml: '<img src="../../UASLIB/img/clock.jpg" width="150" height="150">',
					customClass: {
						icon: 'alert-img-padding-top'
					},
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Aceptar'
				}).then((result) => {
					localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
					localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
					// Remove userData from localStorage
					localStorage.removeItem('userData')
					document.location = "/login";
					});
				break;

			//403 Forbidden
			case 403:
				Swal.fire({
					title: 'Acceso restringido',
					text: "El recurso al que esta tratando de acceder se encuentra restringido.",
					//iconHtml: '<img src="../../UASLIB/img/forbidden.png" width="200" height="160">',
					customClass: {
						icon: 'alert-img-padding-top'
					},
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Aceptar'
				});
				break;

			//404 not found
			case 404:
				Swal.fire({
					title: 'Recurso no encontrado',
					text: "El recurso al que esta tratando de acceder no se encuentra disponible.",
					//iconHtml: '<img src="../../UASLIB/img/notfound.png" width="200" height="160">',
					customClass: {
						icon: 'alert-img-padding-top'
					},
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Aceptar'
				});
				break;

			case 422:
				/*var json = error.response.data.error;

				var x = JSON.stringify(json);
				var obj = JSON.parse(x);

				var content = `<div class="container">`;
				for (const [key, value] of Object.entries(obj)) {
					content += `
						<div class="alert alert-warning" role="alert">
							` +value[0]+
						`</div>
					`;
				}
				content +=`</div>`;

				Swal.fire({
					title: 'Verifique la información',
					icon: 'warning',
					html: content,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Aceptar',
					focusConfirm: true
				});*/
				 break;

			default:
				Swal.fire({
					title: 'Oops.. Algo no salio bien',
					text: "Ocurrio un error inesperado.",
					icon: 'error',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Aceptar'
				});
				break;
		}

		return Promise.reject(error);
	}
);


Vue.prototype.$http = axiosIns

export default axiosIns
