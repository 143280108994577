export default [
  {
    path: "/catalogos/gastos",
    name: "catalogos-gastos",
    component: () =>
      import(
        "@/views/catalogos/gastos/GastosList.vue"
      ),
    meta: {
      pageTitle: "Listar",
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: "Gastos",          
        },
      ],
    },
  }, 
  {
    path: "/catalogos/sucursales",
    name: "catalogos-sucursales",
    component: () =>
      import(
        "@/views/catalogos/mallas/MallaList.vue"
      ),
    meta: {
      pageTitle: "Listar",
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: "Sucursales",          
        },
      ],
    },
  }, 
  {
    path: "/catalogos/empleados",
    name: "catalogos-empleados",
    component: () =>
      import(
        "@/views/catalogos/empleados/empleados-list/EmpleadoList.vue"
      ),
    meta: {
      resource: 'Checador',
      pageTitle: "Listar",
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: "Empleados",          
        },
      ],
    },
  },
  {
    path: "/catalogos/empleados/huella/:id",
    name: 'catalogos-empleados-huella',
    component: () => import("@/views/catalogos/empleados/empleados-view/EmpleadoView.vue"),
    meta: {
      resource: 'Checador',
      pageTitle: 'Capturar Huella',
      breadcrumb: [
        {
          text: 'Catalogos',
        },
        {
          text: 'Empleados',
        }
      ],
    },
  },
];
