<template>
	<div
		id="app"
		class="h-100"
		:class="[skinClasses]"
	>
		<component :is="layout">
			<router-view />
		</component>

		<b-overlay :show="$store.getters['app/getLoading']" variant="light" opacity="0.7" no-wrap class="load-main">
			<template #overlay>
				<div>
					<img src="/logo-main.png" alt="Logo"/>
				</div>
				<div class="load-sppiner">
					<div class="effect-1 effects"></div>
					<div class="effect-2 effects"></div>
					<div class="effect-3 effects"></div>
				</div>
			</template>
		</b-overlay>

		<scroll-to-top v-if="enableScrollToTop" />
	</div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import {
	BOverlay,
} from 'bootstrap-vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
	components: {
		BOverlay,

		// Layouts
		LayoutHorizontal,
		LayoutVertical,
		LayoutFull,

		ScrollToTop,
	},
	// ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
	// Currently, router.currentRoute is not reactive and doesn't trigger any change
	computed: {
		layout() {
			if (this.$route.meta.layout === 'full') return 'layout-full'
			return `layout-${this.contentLayoutType}`
		},
		contentLayoutType() {
			return this.$store.state.appConfig.layout.type
		},
	},
	beforeCreate() {
		// Set colors in theme
		const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = colors.length; i < len; i++) {
			$themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
		}

		// Set Theme Breakpoints
		const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = breakpoints.length; i < len; i++) {
			$themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
		}

		// Set RTL
		const { isRTL } = $themeConfig.layout
		document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
	},
	setup() {
		const { skin, skinClasses } = useAppConfig()
		const { enableScrollToTop } = $themeConfig.layout

		// If skin is dark when initialized => Add class to body
		if (skin.value === 'dark') document.body.classList.add('dark-layout')

		// Provide toast for Composition API usage
		// This for those apps/components which uses composition API
		// Demos will still use Options API for ease
		provideToast({
			hideProgressBar: true,
			closeOnClick: false,
			closeButton: false,
			icon: false,
			timeout: 3000,
			transition: 'Vue-Toastification__fade',
		})

		// Set Window Width in store
		store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
		const { width: windowWidth } = useWindowSize()
		watch(windowWidth, val => {
			store.commit('app/UPDATE_WINDOW_WIDTH', val)
		})

		return {
			skinClasses,
			enableScrollToTop,
		}
	},
}
</script>

<style>
	.load-main {
		z-index: 100 !important;
	}
	.load-sppiner {
		position: absolute;
		left: calc(50% - 35px);
		top: 50%;
		width: 55px;
		height: 55px;
		border-radius: 50%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		border: 3px solid transparent;
	}
	.load-sppiner .effect-1,
	.load-sppiner .effect-2 {
		position: absolute;
		width: 100%;
		height: 100%;
		border: 3px solid transparent;
		border-left: 3px solid rgba(63, 151, 66, 1);
		border-radius: 50%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.load-sppiner .effect-1 {
		animation: rotate 1s ease infinite;
	}
	.load-sppiner .effect-2 {
		animation: rotateOpacity 1s ease infinite 0.1s;
	}
	.load-sppiner .effect-3 {
		position: absolute;
		width: 100%;
		height: 100%;
		border: 3px solid transparent;
		border-left: 3px solid rgba(63, 151, 66, 1);
		-webkit-animation: rotateOpacity 1s ease infinite 0.2s;
		animation: rotateOpacity 1s ease infinite 0.2s;
		border-radius: 50%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.load-sppiner .effects {
		transition: all 0.3s ease;
	}
</style>